import { HomeHero, Layout, MuseumProgram, ContentNotFound, LearningCard } from "@common";
import { ISSRPageData } from '@interfaces'
import { PageService } from '@services'
import { createNavLink } from "@utils";
import { observer } from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import dynamic from 'next/dynamic'
const SelectionsFromWork = dynamic(
  () => import('../components/common/SelectionsFromWork')
)

const DiscoverSsm = dynamic(() => import('../components/common/DiscoverSsm'))
const DiscoverArt = dynamic(() => import('../components/common/DiscoverArt'))

const HomeScreen = observer(
  ({ pageData, global: { navigation } }: ISSRPageData) => {
    const { t } = useTranslation()
    const router = useRouter()
    if (pageData.error) {
      return (
        <Layout
          title={t('common:ssmPageTitle')}
          navigation={navigation}
          path={router.asPath}
        >
          <ContentNotFound infoType="serverSideError" />
        </Layout>
      )
    }

    return (
      <Layout
        title={t('common:ssmPageTitle')}
        navigation={navigation}
        path={router.asPath}
        isHomePage={true}
      >
        <HomeHero swiperCollection={pageData.data.headlines} />
        {pageData.data?.currentMuseumProgram.length && (
          <MuseumProgram tabData={pageData.data.currentMuseumProgram} />
        )}

        {pageData.data?.selectionsFromCollections.length > 0 && (
          <SelectionsFromWork
            isHomePage={true}
            data={pageData.data.selectionsFromCollections}
            sectionTitle={t('common:selectionsFromCollections.title2')}
            sectionSubTitle={t('common:selectionsFromCollections.title1')}
            sectionDescription={t(
              'common:selectionsFromCollections.description'
            )}
            footerButtonLabel={t(
              'common:selectionsFromCollections.buttonLabel'
            )}
            footerButtonOnPress={() =>
              router.push(createNavLink('collections', router.locale))
            }
          />
        )}
        {pageData.data?.discoverArt.length > 0 && (
          <DiscoverArt
            subTitle={t('common:discoverSsm.title1')}
            title={t('common:discoverSsm.title2')}
            description={t('common:discoverSsm.description')}
            sliderCollection={pageData.data?.discoverArt}
          />
        )}

        <LearningCard/>

        {pageData.data?.exploreSSM.length > 0 && (
          <DiscoverSsm
            subTitle={t('common:discoverSsm.title1')}
            title={t('common:discoverSsm.title2')}
            description={t('common:discoverSsm.description')}
            sliderCollection={pageData.data?.exploreSSM}
          />
        )}
      </Layout>
    )
  }
)

export default HomeScreen

export async function getStaticProps({ locale }) {
  const pageData = {
    data: null,
    error: null,
  }

  try {
    const collection = await PageService.getHomePage(locale)

    pageData.data = collection
  } catch (err) {
    if (err instanceof Error) {
      pageData.error = { message: err.message }
    }
  }

  return {
    props: { pageData },
    revalidate: 120,
  }
}
